import { ClockCircleOutlined, NotificationOutlined } from "@ant-design/icons";
import { Card, Timeline } from "antd";
import React from "react";
import { formatDate } from "../../../utils/notificationUtil";
import { OrderStatus } from "../home/statusOrder";
import { TimelineInterface } from "./OrderDetails";
interface props {
  timeline: TimelineInterface[];
  message_ru: boolean;
}

function TimelineOrder({ timeline, message_ru }: props) {
  return (
    <Card style={{ overflow: "auto", width: "100%" }}>
      <Timeline
        mode={"left"}
        pending={timeline.length === 0 ? "Ожидание импорта трек-кода" : null}
      >
        {timeline.map((time: TimelineInterface, index) => (
          <Timeline.Item
            label={
              index !== timeline.length - 1 &&
              timeline[index].message === timeline[index + 1].message
                ? null
                : index !== 0
                ? formatDate(
                    !!time.actionDate ? time.actionDate : time.createdAt
                  )
                : null
            }
            key={time._id}
            dot={
              index !== timeline.length - 1 &&
              timeline[index].message ===
                timeline[index + 1].message ? null : time.status ===
                  OrderStatus.SENT_FROM_CHINA ||
                time.status === OrderStatus.ARRIVED_AT_KAZAKHSTAN ? (
                <NotificationOutlined
                  className="timeline-clock-icon"
                  style={{
                    color: `${
                      time.status === OrderStatus.SENT_FROM_CHINA
                        ? "#e76f51"
                        : "currentColor"
                    }`,
                  }}
                />
              ) : (
                <NotificationOutlined className="timeline-clock-icon" />
              )
            }
            color={
              time.status === OrderStatus.SENT_FROM_CHINA ||
              time.status === OrderStatus.ARRIVED_AT_KAZAKHSTAN
                ? "#00CCFF"
                : "grey"
            }
          >
            {index !== timeline.length - 1 &&
            timeline[index].message === timeline[index + 1].message
              ? null
              : message_ru
              ? timeline[index].message_ru
              : timeline[index].message}
            {/* {message_ru
              ? timeline[index + 1].message_ru
              : timeline[index + 1].message} */}
          </Timeline.Item>
        ))}
      </Timeline>
    </Card>
  );
}

export default TimelineOrder;
