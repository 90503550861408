import {
  Button,
  Card,
  Space,
  Tag,
  Table,
  Modal,
  Spin,
  Row,
  Form,
  Input,
  Upload,
} from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import OrderService from "../../service/OrderService";
import { ColumnsType } from "antd/lib/table";
import { OrderStatus } from "../../views/app-views/home/statusOrder";
import { getOrderStatusEnumText } from "../../utils/getEnumText";
import BarcodeReader from "react-barcode-reader";
import { openNotificationWithIcon } from "../../utils/notificationUtil";
import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import "./style.css";
import {
  UploadRequestOption as RcCustomRequestOptions,
  UploadProgressEvent,
} from "rc-upload/lib/interface";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/lib/upload";

interface openEditInterface {
  open: boolean;
  orderGroupId: string;
}

interface props {
  openEdit: openEditInterface;

  //   setOpenEdit: Dispatch<SetStateAction<openEditInterface>>;
}

interface BaseOrderType {
  _id: string;
  orders: number;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  recivedOrderCount: number;
}

interface orderType extends BaseOrderType {
  client_name: string;
  client_tel: string;
  status: OrderStatus;
  title: string;
  iin?: string;
}

interface DataType {
  orderData: orderType[];
  total: number;
}

const { confirm } = Modal;

const AddOrderBarCode = ({ openEdit }: props) => {
  const [orderLoading, setOrderLoading] = useState(true);
  const [scanBarcodeMode, setScanBarCodeMode] = useState(false);
  const [addLoading, setAddloaing] = useState(false);

  const [excelUploadModal, setExcelUploadModal] = useState(false);
  const [barcodeListModal, setBarcodeListModal] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [progress, setProgress] = useState(0);
  const [fileUploading, setFileUploading] = useState(false);
  const [formExcel] = Form.useForm();

  const [orders, setOrders] = useState<DataType>({
    orderData: [],
    total: 0,
  });

  useEffect(() => {
    const fetchOrder = async () => {
      setOrderLoading(true);

      const result = await OrderService.fetchOrder(openEdit.orderGroupId, 1);

      setOrders({ orderData: result.data.orders, total: result.data.total });

      setOrderLoading(false);
    };

    fetchOrder();
  }, [openEdit.orderGroupId]);

  const deleteOrder = async (id: string) => {
    const res = await OrderService.delete(id);

    const newOrderFilter = orders.orderData.filter((item) => {
      return item._id !== res.data;
    });

    setOrders({
      orderData: newOrderFilter,
      total: orders.total - 1,
    });
  };

  const deleteOrderConfirm = (id: string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "确认删除条码？",
      okText: "确认",
      cancelText: "取消",
      onOk() {
        deleteOrder(id);
      },
    });
  };

  const columns: ColumnsType<orderType> = [
    {
      title: "条码",
      dataIndex: "barCode",
      key: "name",
      render: (text) => <b>{text}</b>,
    },

    {
      title: "状况",
      key: "status",
      dataIndex: "status",
      render: (text: string, record: orderType) => {
        const data = getOrderStatusEnumText(record.status);
        if (data) {
          return <Tag color={data.color}>{data.text}</Tag>;
        }
      },
    },
    {
      title: "IIN",
      key: "iin",
      dataIndex: "iin",
    },
    {
      title: "更多",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            danger
            onClick={() => deleteOrderConfirm(record._id)}
            //   onClick={() => openOrderTimeline(record)}
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];

  const orderPageChangeHandle = async (page: number, pageSize: number) => {
    setOrderLoading(true);
    const res = await OrderService.fetchOrder(openEdit.orderGroupId, page);
    setOrders({
      orderData: res.data.orders,
      total: res.data.total,
    });
    setOrderLoading(false);
  };

  const onFinish = async (values: any) => {
    try {
      const res: any = await OrderService.scanAndCreateOrder({
        barCode: values.barCode,
        orderGroup: openEdit.orderGroupId,
      });
      console.log(res.barCode);

      setAddloaing(false);
      if (!!res.barCode) {
        openNotificationWithIcon("success", `已添加${res.barCode}`, "");

        setOrders({
          orderData: [res, ...orders.orderData],
          total: orders.total + 1,
        });
      }
    } catch (error: any) {
      console.error(error);
      setAddloaing(false);

      if (error.response.status === 422) {
        openNotificationWithIcon("error", `条码已存在`, "");
        return;
      }
      openNotificationWithIcon("error", error.response.msg, "");
    }
  };

  const onExcelFinish = async (values: any) => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file as RcFile);
    });

    try {
      // setUploading(true);
      // You can use any AJAX library you like
      setFileUploading(true);
      const res = await OrderService.importBarcodeExcel(
        formData,
        openEdit.orderGroupId
      );
      openNotificationWithIcon("success", "OK", "");
      // setUploading(false);
      setFileList([]);
      setExcelUploadModal(false);
      setFileUploading(false);
      // onClose();
      window.location.reload();
    } catch (error) {
      console.error(error);
      setFileList([]);
      setExcelUploadModal(false);
      setFileUploading(false);
      window.location.reload();
      // setUploading(false);
      //alert("出错");
    }
  };

  const onBarcodeListFinish = async (values: any) => {
    try {
      // console.log(values);
      setFileUploading(true);
      console.log("asd");

      let arrBarcodes = values.barcodes.split("\n");
      console.log(arrBarcodes);

      const res = await OrderService.importBarcodeList({
        orderGroup: openEdit.orderGroupId,
        // actionDate: values.actionDate,
        barcodes: arrBarcodes.filter((n: string) => n),
      });
      openNotificationWithIcon("success", "OK", "");
      // setUploading(false);

      setFileUploading(false);
      setBarcodeListModal(false);
      window.location.reload();

      // const res: any = await OrderService.importBarcodeExcel({
      //   ...values,
      //   orderGroup: openEdit.orderGroupId,
      // });
      // console.log(res.barCode);
      // setAddloaing(false);
      // if (!!res.barCode) {
      //   openNotificationWithIcon("success", `已添加${res.barCode}`, "");
      //   setOrders({
      //     orderData: [res, ...orders.orderData],
      //     total: orders.total + 1,
      //   });
      // }
    } catch (error: any) {
      console.error(error);

      if (error.response.status === 422) {
        openNotificationWithIcon("error", `条码已存在`, "");
        return;
      }
      openNotificationWithIcon("error", error.response.msg, "");
      setFileUploading(false);
      setBarcodeListModal(false);
      window.location.reload();
    }
  };

  const getFile = (e: any) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const props: UploadProps = {
    multiple: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isLt5M = file.size / 1024 / 1024 <= 5;
      if (!isLt5M) {
        // alert('Image must smaller than 5MB!');
        return false;
      }
      setFileList([...fileList, file]);

      return false;
    },

    fileList,
    accept: ".xls, .xlsx",
  };

  return (
    <Card
      className={scanBarcodeMode ? "scanMode" : ""}
      style={{ marginBottom: 10 }}
      //   bodyStyle={{ background: scanBarcodeMode ? "#e8e8e8" : "#fff" }}
    >
      <Row justify="space-between">
        <Button
          style={{ marginBottom: 20 }}
          type={scanBarcodeMode ? "default" : "primary"}
          onClick={() => setScanBarCodeMode((current) => !current)}
        >
          {scanBarcodeMode ? "取消条码模式" : "添加条码模式"}
        </Button>
        {scanBarcodeMode ? (
          <div>
            <Button
              type="primary"
              icon={<UploadOutlined />}
              style={{ backgroundColor: "green", border: "none" }}
              onClick={() => setExcelUploadModal(true)}
            >
              Excel / 拼量上传
            </Button>

            <Button
              //type="primary"
              //icon={<UploadOutlined />}
              //style={{ backgroundColor: "green", border: "none" }}
              onClick={() => setBarcodeListModal(true)}
            >
              拼量复制Excel
            </Button>
          </div>
        ) : null}

        {scanBarcodeMode ? (
          <Form
            name="basic"
            onFinish={(values: any) => onFinish(values)}
            layout="inline"
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label=""
              name="barCode"

              // rules={[{ required: true, message: "Пожалуйста, введите длину!!" }]}
            >
              <Input placeholder="条码" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                添加
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Row>

      <Table
        loading={orderLoading}
        rowKey="_id"
        columns={columns}
        dataSource={orders.orderData}
        pagination={{
          pageSize: 5,
          total: orders.total,
          onChange: orderPageChangeHandle,
        }}
      />
      {scanBarcodeMode === true ? (
        <BarcodeReader
          onError={(err: any) => {
            console.warn(err);
          }}
          onScan={(data: string) => {
            setAddloaing(true);
            OrderService.scanAndCreateOrder({
              barCode: data,
              orderGroup: openEdit.orderGroupId,
            })
              .then((res: any) => {
                setAddloaing(false);
                openNotificationWithIcon("success", `已添加${res.barCode}`, "");

                setOrders({
                  orderData: [res, ...orders.orderData],
                  total: orders.total + 1,
                });
              })
              .catch((err) => {
                console.log(err);
                setAddloaing(false);

                if (err?.response?.status === 422) {
                  openNotificationWithIcon("error", `条码已存在`, "");
                }
                // openNotificationWithIcon(
                //   "error",
                //   `${res.barCode} 条码已存在`,
                //   ""
                // );
              });

            // setScanCodes((current) => [...current, data]);
          }}
        />
      ) : null}
      {/* <Table
    loading={orderLoading}
    columns={columns}
    dataSource={orders.orderData}
    // expandable={{
    //   expandedRowRender,
    //   expandedRowKeys: orderExpandedRowKeys,
    //   onExpand: onExpandedRowsChange,
    // }}
    rowKey="_id"
    pagination={{
      pageSize: 12,
      total: orders.total,
      onChange: orderGroupPageChangeHandle,
    }}
  /> */}

      <Modal
        centered
        width={"min-content"}
        closable={false}
        title=""
        open={addLoading}
        footer={null}
        onCancel={() => setAddloaing(false)}
      >
        <Spin />
      </Modal>

      <Modal
        centered
        // width={"min-content"}
        closable={true}
        title="Excel"
        open={excelUploadModal}
        footer={
          <Button
            type="primary"
            form="excelForm"
            htmlType="submit"
            disabled={fileUploading}
            loading={fileUploading}
          >
            OK
          </Button>
        }
        onCancel={() => {
          setExcelUploadModal(false);
          formExcel.resetFields();
        }}
        // onOk={}
      >
        <Form
          name="basic"
          layout="vertical"
          id="excelForm"
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onExcelFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={formExcel}
        >
          <Form.Item
            label="Excel"
            name="file"
            getValueFromEvent={getFile}
            rules={[{ required: true, message: "请上传!" }]}
          >
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>Excel 上传 / Загрузить </Button>
            </Upload>
            {/* <Upload beforeUpload={() => false}>
              <Button icon={<UploadOutlined />}>Excel 上传 / Загрузить </Button>
            </Upload> */}
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        centered
        // width={"min-content"}
        closable={true}
        title="条码/Код"
        open={barcodeListModal}
        // footer={null}
        footer={
          <Button
            type="primary"
            form="barcodeList_form"
            htmlType="submit"
            loading={fileUploading}
            disabled={fileUploading}
          >
            OK
          </Button>
        }
        onCancel={() => setBarcodeListModal(false)}
        // onOk={}
      >
        <Form
          name="basic"
          layout="vertical"
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onBarcodeListFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          id="barcodeList_form"
        >
          <Space />
          <Form.Item
            label="条码/Код"
            name="barcodes"
            // rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input.TextArea placeholder="复制粘贴 / Вставить" />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default AddOrderBarCode;
