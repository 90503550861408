import { current } from "@reduxjs/toolkit";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  List,
  Modal,
  notification,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useRef, useState } from "react";
import BarcodeReader from "react-barcode-reader";
import OrderService from "../../../service/OrderService";
import SearchBarcode from "../../../components/searchBarcode/SearchBarcode";

function ScanReader() {
  // const handleWareHouseOk = async () => {
  //   setLoadingModal(true);
  //   const response = await OrderService.receive({
  //     barCodes: scanedCodes,
  //   });

  //   if (!!response.data.message) {
  //     notification.success({
  //       message: `OK`,
  //       description: response.data.message,
  //       placement: "topRight",
  //     });

  //     setScanCodes([]);
  //     setWareHouseModal((current) => ({ ...current, open: false }));
  //   } else {
  //     notification.warn({
  //       message: ``,
  //       description: "出错",
  //       placement: "topRight",
  //     });
  //   }

  //   setLoadingModal(false);
  // };
  return (
    <div style={{ height: "100%", margin: 20 }}>
      <SearchBarcode isFilial={false} />
    </div>
  );
}

//// >
// {WareHouses.map((warehouse: string, index: number) => (
//   <Select.Option key={warehouse} value={warehouse}>
//     {warehouse}
//   </Select.Option>
// ))}
// </Select>
export default ScanReader;
