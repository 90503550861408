import "./App.css";
import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./views/auth-views/login";
import "antd/dist/antd.css";
import CheckOrder from "./views/app-views/checkOrder";
import AppView from "./views/app-views";

function App() {
  return (
    <>
      <Routes>
        <Route path="login" element={<Login />} />

        <Route path="admin/*" element={<AppView />} />

        <Route index element={<CheckOrder />} />
      </Routes>
      {/* */}
    </>
  );
}

export default App;
