import React, { useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import {
  DeleteColumnOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import OrderService from "../../../service/OrderService";
import OrderDetails from "../order-details";

import { getOrderStatusEnumText } from "../../../utils/getEnumText";

import OrderGroupService from "../../../service/OrderGroupService";
import OrderTimeLine from "../order-timeline";
// import { ENDPOINT } from "../../../configs/AppConfig";
// import socketIOClient from "socket.io-client";
import { formatDate } from "../../../utils/notificationUtil";
// import BarcodeReader from "react-barcode-reader";
// import { current } from "@reduxjs/toolkit";
import { OrderStatus } from "../home/statusOrder";
import CityService from "../../../service/CityService";
import FilialDetails from "./FilialDetails";
// import { Link } from "react-router-dom";
import SearchBarcode from "../../../components/searchBarcode/SearchBarcode";

interface DataType {
  orderData: orderType[];
  total: number;
}

interface OrderGroupDataType {
  orderGroupsData: BaseOrderType[];
  total: number;
}

interface orderType extends BaseOrderType {
  client_name: string;
  client_tel: string;
  status: OrderStatus;
  title: string;
  iin?: string;
}

interface BaseOrderType {
  _id: string;
  orders: number;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  dest_city?: { titleRU: string };
  recivedOrderCount: number;
}

interface ICity {
  _id: string;
  titleRU: string;
}

interface ICityList {
  cities: ICity[];
  currentPage: number;
  total: number;
}

const Filial = () => {
  const [orderGroups, setOrderGroups] = useState<OrderGroupDataType>({
    orderGroupsData: [],
    total: 0,
  });
  const [openEdit, setOpenEdit] = useState({
    open: false,
    orderGroupId: "",
  });

  const [openTimeline, setOpenTimeline] = useState({
    open: false,
    orderId: "",
  });

  const [orders, setOrders] = useState<DataType>({
    orderData: [],
    total: 0,
  });

  const [orderLoading, setOrderLoading] = useState(false);
  const [orderGroupLoading, setOrderGroupLoading] = useState(false);

  const [orderExpandedRowKeys, setOrderExpandedRowKeys] = useState<string[]>(
    []
  );
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isReceiveModalOpen, setIsReceiveModalOpen] = useState(false);

  const [cities, setCitites] = useState<ICityList>({
    cities: [],
    currentPage: 1,
    total: 0,
  });

  const [citySelected, setSelectedCity] = useState("");

  useEffect(() => {
    async function fetchCitits() {
      const res = await CityService.fetchFilter(1, 100);

      setCitites(res.data);
    }
    fetchCitits();
  }, []);

  const showModal = () => {
    setIsAddModalOpen(true);
  };

  const handleOk = () => {
    setIsAddModalOpen(false);
  };

  const handleCancel = () => {
    setIsAddModalOpen(false);
  };

  const onFinish = async (values: any) => {
    const response: any = await OrderGroupService.add({
      ...values,
      inKazakhstan: true,
    });

    setOrderGroups({
      ...orderGroups,
      orderGroupsData: [
        ...orderGroups.orderGroupsData,
        { ...response, orders: 0, recivedOrderCount: 0 },
      ],
    });

    setIsAddModalOpen(false);

    setOpenEdit({
      open: true,
      orderGroupId: response.data._id,
    });
  };

  const { confirm } = Modal;

  const fetchOrders = async (page = 1, value: string) => {
    try {
      setSelectedCity(value);
      // console.log(value);
      setOrderGroupLoading(true);
      const result = await OrderGroupService.fetchOrderGroup(
        page,
        12,
        true,
        "",
        value
      );
      setOrderGroups({
        orderGroupsData: result.data.orderGroups,
        total: result.data.total,
      });
      setOrderGroupLoading(false);
    } catch (error) {
      setOrderGroupLoading(false);
    }
  };

  const orderGroupPageChangeHandle = async (page: number, pageSize: number) => {
    await fetchOrders(page, citySelected);
  };

  const orderPageChangeHandle = async (page: number, pageSize: number) => {
    setOrderLoading(true);
    await fetchOrders(page, citySelected);
    // const res = await OrderService.fetchOrder(orderExpandedRowKeys[0], page);
    // setOrders({
    //   orderData: res.data.orders,
    //   total: res.data.total,
    // });
    setOrderLoading(false);
  };

  const openOrderDetail = (recordId: string) => {
    setOpenEdit({ open: true, orderGroupId: recordId });
    return;
  };

  const openOrderTimeline = (record: orderType) => {
    setOpenTimeline({ open: true, orderId: record._id });
    return;
  };

  const expandedRowRender = (row: any) => {
    const columns: ColumnsType<orderType> = [
      {
        title: "条码",
        dataIndex: "barCode",
        key: "name",
        render: (text) => <b>{text}</b>,
      },

      {
        title: "状况",
        key: "status",
        dataIndex: "status",
        render: (text: string, record: orderType) => {
          const data = getOrderStatusEnumText(record.status);
          if (data) {
            return <Tag color={data.color}>{data.text}</Tag>;
          }
        },
      },
      {
        title: "IIN",
        key: "iin",
        dataIndex: "iin",
      },
      {
        title: "更多",
        key: "action",
        render: (_, record) => (
          <Space size="middle">
            <Button type="primary" onClick={() => openOrderTimeline(record)}>
              查看
            </Button>
          </Space>
        ),
      },
    ];

    return (
      <Table
        loading={orderLoading}
        rowKey="_id"
        columns={columns}
        dataSource={orders.orderData}
        pagination={{
          pageSize: 5,
          total: orders.total,
          onChange: orderPageChangeHandle,
        }}
      />
    );
  };

  const deleteOrderGroup = async (id: string) => {
    await OrderGroupService.delete(id);
    window.location.reload();
  };

  const deleteOrderGroupConfirm = (id: string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "确认删除条码组？",
      okText: "确认",
      cancelText: "取消",
      onOk() {
        deleteOrderGroup(id);
      },
    });
  };

  const handleScanCode = async (data: string) => {
    const response = await OrderService.search({ barCode: data });
    setOrderGroups((current) => {
      const newData = current.orderGroupsData.filter(function (value, index) {
        return value._id === response.data.orderGroup;
      });

      return {
        orderGroupsData: newData,
        total: 1,
      };
    });
  };

  const columns: ColumnsType<BaseOrderType> = [
    {
      title: "Название",
      dataIndex: "title",
      render: (text) => <b>{text}</b>,
    },
    {
      title: "Филиал",
      dataIndex: "dest_city.titleRU",
      key: "dest_city.titleRU",
      render: (_, record) => <span>{record?.dest_city?.titleRU}</span>,
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <span>{formatDate(text)}</span>,
    },

    // {
    //   title: "Доставленное",
    //   dataIndex: ["recivedOrderCount", "orders"],
    //   key: "recivedOrderCount",
    //   render: (text, record) => (
    //     <span>{`${record.recivedOrderCount} / ${record.orders}`}</span>
    //   ),
    // },
    // {
    //   title: "客户绑定数量",
    //   dataIndex: ["recivedOrderCount", "orders"],
    //   key: "recivedOrderCount",
    //   render: (text, record) => (
    //     <span>{`${record.recivedOrderCount} / ${record.orders}`}</span>
    //   ),
    // },
    {
      title: "Ещё",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => openOrderDetail(record._id)}>
            Скан
            <QrcodeOutlined />
          </Button>
          {record.orders === 0 && (
            <Button
              type="primary"
              onClick={() => deleteOrderGroupConfirm(record._id)}
              danger
            >
              <DeleteOutlined />
            </Button>
          )}
        </Space>
      ),
    },
  ];

  // useEffect(() => {
  //   fetchOrders("");
  // }, []);

  // useEffect(() => {
  //   const socket = socketIOClient(ENDPOINT);
  //   socket.on("OPEN_ORDERGROUP", (data) => {
  //     openOrderDetail(data.orderGroupId);
  //   });
  //   socket.on("BARCODE_SEARCH_SCANNED", (data) => {
  //     openOrderTimeline(data.order);
  //   });
  // }, []);

  const onExpandedRowsChange = async (expanded: any, record: any) => {
    if (expanded) {
      setOrderLoading(true);
      setOrderExpandedRowKeys([record._id]);
      const result = await OrderService.fetchOrder(record._id, 1);
      setOrders({ orderData: result.data.orders, total: result.data.total });
    } else {
      setOrderExpandedRowKeys([]);
    }
    setOrderLoading(false);
  };
  return (
    <Card>
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <div>
          <Button onClick={() => showModal()} type="primary">
            Добавить
          </Button>
          <Select
            placeholder="Филиал"
            onChange={(val) => fetchOrders(1, val)}
            // defaultValue={type || BarCodeType.PACKAGE}
            style={{ width: 120, marginLeft: 32 }}
            options={cities.cities.map((item: ICity) => ({
              label: item.titleRU,
              value: item._id,
            }))}
          />
        </div>

        <Button
          onClick={() => {
            setIsReceiveModalOpen(true);
          }}
          type="dashed"
        >
          Получение
        </Button>
      </Row>

      {/* <BarcodeReader
    onError={(err: any) => {
      console.log(err);
    }}
    onScan={(data: string) => {
      handleScanCode(data);
    }}
  /> */}
      <Table
        loading={orderGroupLoading}
        columns={columns}
        dataSource={orderGroups.orderGroupsData}
        expandable={{
          expandedRowRender,
          expandedRowKeys: orderExpandedRowKeys,
          onExpand: onExpandedRowsChange,
        }}
        rowKey="_id"
        pagination={{
          pageSize: 12,
          total: orderGroups.total,
          onChange: orderGroupPageChangeHandle,
        }}
      />
      {openEdit.open && (
        <FilialDetails openEdit={openEdit} setOpenEdit={setOpenEdit} />
      )}
      {openTimeline.open && (
        <OrderTimeLine openEdit={openTimeline} setOpenEdit={setOpenTimeline} />
      )}

      <Modal
        title="Basic Modal"
        open={isAddModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {/* <Form.Item label="Field A" hidden>
          <Input placeholder="input placeholder" name="d" />
        </Form.Item> */}
        <Form
          id="addOrderGroup"
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="Название" name="title">
            <Input placeholder="" />
          </Form.Item>

          <Form.Item label={"Филиал"} name="city">
            <Select
              // defaultValue={type || BarCodeType.PACKAGE}
              style={{ width: 120 }}
              options={cities.cities.map((item: ICity) => ({
                label: item.titleRU,
                value: item._id,
              }))}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Добавить
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Получение"
        open={isReceiveModalOpen}
        // onOk={handleOk}
        onCancel={() => {
          setIsReceiveModalOpen(false);
        }}
        footer={null}
      >
        <SearchBarcode isFilial={true} />
      </Modal>
    </Card>
  );
};

export default Filial;
