export enum OrderStatus {
  PENDING = "PENDING",
  SENT_FROM_CHINA = "SENT_FROM_CHINA",
  ARRIVED_AT_KAZAKHSTAN = "ARRIVED_AT_KAZAKHSTAN",
  DELIVERED = "DELIVERED",
  BORDER = "BORDER",
  SENT_FROM_KZ_INNER = "SENT_FROM_KZ_INNER",
  RECIEVED_FROM_KZ_INNER = "RECIEVED_FROM_KZ_INNER",
}

export enum OrderStatusColor {
  PENDING = "purple",
  SENT_FROM_CHINA = "red",
  ARRIVED_AT_KAZAKHSTAN = "purple",
  DELIVERED = "cyan",
  BORDER = "yellow",
}

export enum CustomerBarCodeStatus {
  UNREGISTERED = "UNREGISTERED",
}

export enum CustomerBarCodeStatusColor {
  UNREGISTERED = "grey",
}
