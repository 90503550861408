import {
  Button,
  Card,
  DatePicker,
  Drawer,
  InputNumber,
  Modal,
  Result,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
} from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./style.css";
import Meta from "antd/lib/card/Meta";
import OrderService, {
  qrCodeDataInterface,
  QrCodeType,
} from "../../../service/OrderService";
import { API_BASE_URL, ENDPOINT } from "../../../configs/AppConfig";
import socketIOClient from "socket.io-client";
import { getQrObjectIndex } from "../../../utils/getQrCodeType";
import OrderGroupService from "../../../service/OrderGroupService";
import BarcodeReader from "react-barcode-reader";
import { OrderStatus } from "../home/statusOrder";
import { ColumnsType } from "antd/lib/table";
import { getOrderStatusEnumText } from "../../../utils/getEnumText";
import { log } from "console";
import { current } from "@reduxjs/toolkit";
import AddOrderBarCode from "../../../components/addOrderBarCode";
import { openNotificationWithIcon } from "../../../utils/notificationUtil";
import { IORDERS } from "../../../components/searchBarcode/SearchBarcode";
import moment from "moment";

interface openEditInterface {
  open: boolean;
  orderGroupId: string;
}

interface QrCodeInterface {
  createdAt: string;
  img: string;
  isScanned: boolean;
  order: string;
  type: string;
  updatedAt: string;
  __v: number;
  _id: string;
}

export interface warehouseInterface {
  key: string;
  text: string;
}

export enum WarehouseChina {
  GUANZHOU = "GUANZHOU",
  YIWU = "YIMU",
  URUMQI = "URUMQI",
}

export interface TimelineInterface {
  _id: string;
  order: string;
  message: string;
  message_ru: string;
  status: string;
  createdAt: string;
  actionDate: string;
  updatedAt: string;
  __v: number;
}

interface props {
  openEdit: openEditInterface;
  setOpenEdit: Dispatch<SetStateAction<openEditInterface>>;
  setScanCodes?: Dispatch<SetStateAction<IORDERS>>;
}

interface DataType {
  orderData: orderType[];
  total: number;
}

interface OrderGroupDataType {
  orderGroupsData: BaseOrderType[];
  total: number;
}

interface orderType extends BaseOrderType {
  client_name: string;
  client_tel: string;
  status: OrderStatus;
  title: string;
  iin?: string;
}

interface BaseOrderType {
  _id: string;
  orders: number;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  recivedOrderCount: number;
}

interface OrderGroupListInterface {
  inKazakhstan: boolean;
  title: string;
  status: OrderStatus;
  _id: string;
  orders: { status: OrderStatus }[];
}

function OrderDetails({ openEdit, setOpenEdit }: props) {
  const [qrCodes, setQrCodes] = useState<QrCodeInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingWareHouse, setLoadingWareHouse] = useState(false);
  const [wareHouseModal, setWareHouseModal] = useState({
    open: false,
    warehouseSelected: "",
    qrCodeType: "",
    qrCodeId: "",
    warehouses: [],
    actionDate: "",
  });

  const [actionDateModal, setActionDateModal] = useState({
    open: false,

    actionDate: "",
  });

  const [orderGroup, setOrderGroup] = useState<OrderGroupListInterface>({
    inKazakhstan: false,
    title: "",
    status: OrderStatus.PENDING,
    _id: "",
    orders: [],
  });

  const [orders, setOrders] = useState<DataType>({
    orderData: [],
    total: 0,
  });

  const [orderFinance, setOrderFinance] = useState({
    woodFee: "",
    packageFee: "",
    weight: "",
  });

  const [orderLoading, setOrderLoading] = useState(true);

  const onClose = () => {
    setOpenEdit({ ...openEdit, open: false });
  };
  const handleWareHouseOk = async () => {
    if (!wareHouseModal.actionDate) {
      alert("请选择日期 / Выберите дату");
      return;
    }
    setLoadingWareHouse(true);
    const res = await OrderGroupService.sendChina(orderGroup._id, {
      woodFee: parseFloat(orderFinance.woodFee),
      packageFee: parseFloat(orderFinance.packageFee),
      weight: parseFloat(orderFinance.weight),
      warehouse: wareHouseModal.warehouseSelected,
      actionDate: wareHouseModal.actionDate,
    });
    setOrderGroup(res.data);
    // let timeline_data: qrCodeDataInterface = {
    //   qrId: wareHouseModal.qrCodeId,
    //   warehouse: wareHouseModal.warehouseSelected,
    // };
    // await OrderService.createTimeLineAfterScan(timeline_data);

    // await OrderGroupService.update(openEdit.orderGroupId, {
    //   ...orderGroup,
    //   woodFee: parseFloat(orderFinance.woodFee),
    //   packageFee: parseFloat(orderFinance.packageFee),
    //   weight: parseFloat(orderFinance.weight),
    // });
    setLoadingWareHouse(false);
    setWareHouseModal({
      ...wareHouseModal,
      open: false,
      warehouseSelected: "",
    });
  };

  const openOrderTimeline = (record: orderType) => {
    // setOpenTimeline({ open: true, orderId: record._id });
    return;
  };

  // const orderGroupPageChangeHandle = async (page: number, pageSize: number) => {
  //   setOrderGroupLoading(true);
  //   const res = await OrderGroupService.fetchOrderGroup(page, 12, false);
  //   setOrderGroups({
  //     orderGroupsData: res.data.orderGroups,
  //     total: res.data.total,
  //   });
  //   setOrderGroupLoading(false);
  // };

  useEffect(() => {
    // const fetchQrcodes = async () => {
    //   setLoading(true);
    //   const response = await OrderService.fetchQrcode(openEdit.orderGroupId);
    //   setQrCodes(response.data);
    //   setLoading(false);
    // };

    const fetchOrderGroup = async () => {
      const response = await OrderGroupService.fetchOneOrderGroup(
        openEdit.orderGroupId
      );
      setOrderGroup(response.data);
      setLoading(false);
    };

    const fetchOrder = async () => {
      setOrderLoading(true);

      const result = await OrderService.fetchOrder(openEdit.orderGroupId, 1);

      setOrders({ orderData: result.data.orders, total: result.data.total });

      setOrderLoading(false);
    };

    fetchOrderGroup();
    fetchOrder();
  }, [openEdit.orderGroupId]);

  // useEffect(() => {
  //   const socket = socketIOClient(ENDPOINT);
  //   socket.on("QR_SCANNED", (data) => {
  //     setWareHouseModal({
  //       ...wareHouseModal,
  //       open: true,
  //       qrCodeType: data.qrCode.type,
  //       qrCodeId: data.qrCode._id,
  //       warehouses: data.wareHouse,
  //     });
  //   });

  //   socket.on("QR_SCANNED_TIMELINE_CREATED", (data) => {
  //     setQrCodes((current) =>
  //       current.map((obj) => {
  //         if (obj._id === data.qrCode._id) {
  //           return { ...obj, isScanned: data.qrCode.isScanned };
  //         }
  //         return obj;
  //       })
  //     );
  //   });
  // }, []);

  return (
    <Drawer
      title={`【${orderGroup.title}】`}
      placement="right"
      onClose={onClose}
      width={800}
      open={openEdit.open}
    >
      <Spin spinning={loading}>
        {/*  */}
        <AddOrderBarCode openEdit={openEdit} />
        <div className="order_details">
          <Card>
            <div className="qrCode">
              <Card
                style={{ width: 300 }}
                cover={
                  orderGroup.status !== OrderStatus.PENDING ||
                  // orderGroup.status !== OrderStatus.RECIEVED_FROM_KZ_INNER ||
                  (orderGroup?.orders &&
                    orderGroup?.orders.length > 1 &&
                    orderGroup?.orders[0]?.status !== OrderStatus.PENDING) ? (
                    <Result
                      status="success"
                      title="已从中国发货 / Отправлено"
                    />
                  ) : (
                    <Button
                      onClick={() => {
                        // if (orderGroup.orders.length === 0) {
                        //   openNotificationWithIcon(
                        //     "error",
                        //     "跟踪号不能为空",
                        //     ""
                        //   );
                        //   return;
                        // }
                        setWareHouseModal({ ...wareHouseModal, open: true });
                      }}
                    >
                      中国发货
                    </Button>
                  )
                }
                //   actions={[
                //     <SettingOutlined key="setting" />,
                //     <EditOutlined key="edit" />,
                //     <EllipsisOutlined key="ellipsis" />,
                //   ]}
              ></Card>
              <Card
                style={{ width: 300 }}
                cover={
                  orderGroup.status === OrderStatus.ARRIVED_AT_KAZAKHSTAN ||
                  (orderGroup?.orders &&
                    orderGroup?.orders[0]?.status ===
                      OrderStatus.ARRIVED_AT_KAZAKHSTAN) ? (
                    <Result status="success" title="哈国已接货 / Получено" />
                  ) : orderGroup.status === OrderStatus.SENT_FROM_CHINA ||
                    orderGroup.status === OrderStatus.BORDER ||
                    (orderGroup?.orders &&
                      orderGroup?.orders[0]?.status ===
                        OrderStatus.SENT_FROM_CHINA) ||
                    (orderGroup?.orders &&
                      orderGroup?.orders[0]?.status === OrderStatus.BORDER) ? (
                    <Button
                      onClick={async () => {
                        setActionDateModal({ ...actionDateModal, open: true });
                        // const res = await OrderGroupService.recieveKz(
                        //   orderGroup._id
                        // );

                        // setOrderGroup(res.data);
                      }}
                    >
                      哈国接货 / Получить товар из Казахстана
                    </Button>
                  ) : (
                    <p style={{ textAlign: "center", paddingTop: 10 }}>
                      请先发送中国货物 {orderGroup.status} / Пожалуйста, сначала
                      отправьте товары из Китая
                    </p>
                    // <img
                    //   alt={qrCode.type}
                    //   src={`${API_BASE_URL}/qrCode/${qrCode.img}`}
                    // />
                  )
                }
                //   actions={[
                //     <SettingOutlined key="setting" />,
                //     <EditOutlined key="edit" />,
                //     <EllipsisOutlined key="ellipsis" />,
                //   ]}
              ></Card>
            </div>
          </Card>
        </div>
      </Spin>

      {/* // split */}
      <Modal
        title="仓库"
        maskClosable={true}
        closable={true}
        //open={true}
        // style={{ zIndex: 999 }}
        onCancel={() => {
          setWareHouseModal({ ...wareHouseModal, open: false });
          setLoadingWareHouse(false);
        }}
        open={wareHouseModal.open}
        onOk={handleWareHouseOk}
      >
        <Spin size="large" spinning={loadingWareHouse}>
          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder="请选测仓库"
            optionFilterProp="children"
            value={wareHouseModal.warehouseSelected}
            onChange={(value) => {
              setWareHouseModal({
                ...wareHouseModal,
                warehouseSelected: value,
              });
            }}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Select.Option
              key={WarehouseChina.GUANZHOU}
              value={WarehouseChina.GUANZHOU}
            >
              广州
            </Select.Option>
            <Select.Option
              key={WarehouseChina.YIWU}
              value={WarehouseChina.YIWU}
            >
              义乌
            </Select.Option>
            <Select.Option
              key={WarehouseChina.URUMQI}
              value={WarehouseChina.URUMQI}
            >
              乌鲁木齐
            </Select.Option>
          </Select>

          <Row style={{ marginTop: 20 }} align="middle">
            <span style={{ marginRight: 10 }}>日期 / Дата:</span>
            <DatePicker
              showTime
              onChange={(value) => {
                setWareHouseModal({
                  ...wareHouseModal,
                  actionDate: moment(value).toISOString(),
                });
              }}
            />
          </Row>

          {/* <Row style={{ marginTop: 20 }}>
            <span style={{ marginRight: 10 }}>包装费:</span>
            <InputNumber
              value={orderFinance.packageFee}
              placeholder="包装费$"
              onChange={(val) =>
                setOrderFinance((current) => ({
                  ...current,
                  packageFee: val?.toString() || "",
                }))
              }
            />
          </Row>
          <Row style={{ marginTop: 20 }}>
            <span style={{ marginRight: 10 }}>重量:</span>
            <InputNumber
              value={orderFinance.weight}
              placeholder="重量kg"
              onChange={(val) =>
                setOrderFinance((current) => ({
                  ...current,
                  weight: val?.toString() || "",
                }))
              }
            />
          </Row> */}
        </Spin>
      </Modal>

      <Modal
        title="接受Almaty"
        maskClosable={true}
        closable={true}
        //open={true}
        // style={{ zIndex: 999 }}
        onCancel={() => {
          setActionDateModal({ ...actionDateModal, open: false });
          //setLoadingWareHouse(false);
        }}
        open={actionDateModal.open}
        onOk={async () => {
          if (!actionDateModal.actionDate) {
            alert("请选择日期 / Выберите дату");
            return;
          }
          const res = await OrderGroupService.recieveKz(orderGroup._id, {
            actionDate: actionDateModal.actionDate,
          });

          setOrderGroup(res.data);
        }}
      >
        <Spin size="large" spinning={false}>
          <Row style={{ marginTop: 20 }} align="middle">
            <span style={{ marginRight: 10 }}>日期 / Дата:</span>
            <DatePicker
              showTime
              onChange={(value) => {
                setActionDateModal({
                  ...actionDateModal,
                  actionDate: moment(value).toISOString(),
                });
              }}
            />
          </Row>

          {/* <Row style={{ marginTop: 20 }}>
            <span style={{ marginRight: 10 }}>包装费:</span>
            <InputNumber
              value={orderFinance.packageFee}
              placeholder="包装费$"
              onChange={(val) =>
                setOrderFinance((current) => ({
                  ...current,
                  packageFee: val?.toString() || "",
                }))
              }
            />
          </Row>
          <Row style={{ marginTop: 20 }}>
            <span style={{ marginRight: 10 }}>重量:</span>
            <InputNumber
              value={orderFinance.weight}
              placeholder="重量kg"
              onChange={(val) =>
                setOrderFinance((current) => ({
                  ...current,
                  weight: val?.toString() || "",
                }))
              }
            />
          </Row> */}
        </Spin>
      </Modal>
    </Drawer>
  );
}

export default OrderDetails;
