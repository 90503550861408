import { Button, Card, Drawer, Modal, Result, Select, Spin } from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import AddOrderBarCode from "../../../components/addOrderBarCode";
import OrderService, {
  qrCodeDataInterface,
} from "../../../service/OrderService";
import UpdateOrderBarCode from "./UpdateOrderBarCode";
import OrderGroupService from "../../../service/OrderGroupService";
import { OrderStatus } from "../home/statusOrder";
import { openNotificationWithIcon } from "../../../utils/notificationUtil";

interface openEditInterface {
  open: boolean;
  orderGroupId: string;
}

interface props {
  openEdit: openEditInterface;
  setOpenEdit: Dispatch<SetStateAction<openEditInterface>>;
}

export interface warehouseInterface {
  key: string;
  text: string;
}

const FilialDetails = ({ openEdit, setOpenEdit }: props) => {
  const [loading, setLoading] = useState(true);

  const [wareHouseModal, setWareHouseModal] = useState({
    open: false,
    warehouseSelected: "",

    warehouses: [],
  });

  const [loadingWareHouse, setLoadingWareHouse] = useState(false);

  const [orderGroup, setOrderGroup] = useState({
    inKazakhstan: false,
    title: "",
    status: "",
    _id: "",
  });

  const onClose = () => {
    setOpenEdit({ ...openEdit, open: false });
  };

  const handleWareHouseOk = async () => {
    setLoadingWareHouse(true);
    // let timeline_data: qrCodeDataInterface = {
    //   qrId: wareHouseModal.qrCodeId,
    //   warehouse: wareHouseModal.warehouseSelected,
    // };
    // await OrderService.createTimeLineAfterScan(timeline_data);
    setLoadingWareHouse(false);
    setWareHouseModal({
      ...wareHouseModal,
      open: false,
      warehouseSelected: "",
    });
  };

  useEffect(() => {
    const fetchOrderGroup = async () => {
      const response = await OrderGroupService.fetchOneOrderGroup(
        openEdit.orderGroupId
      );
      setOrderGroup(response.data);
      setLoading(false);
    };

    fetchOrderGroup();
  }, [openEdit.orderGroupId]);
  return (
    <Drawer
      title={`【${orderGroup.title}】`}
      placement="right"
      onClose={onClose}
      width={800}
      open={openEdit.open}
    >
      <Spin spinning={loading}>
        {/*  */}
        <UpdateOrderBarCode openEdit={openEdit} />
        <div className="order_details">
          <Card>
            <div className="qrCode">
              <Card
                style={{ width: 300 }}
                cover={
                  orderGroup.status === OrderStatus.SENT_FROM_KZ_INNER ||
                  orderGroup.status === OrderStatus.RECIEVED_FROM_KZ_INNER ? (
                    <Result status="success" title="Отправлено" />
                  ) : orderGroup.status === OrderStatus.PENDING ? (
                    <Button
                      onClick={async () => {
                        const res: any = await OrderService.sendKzInner({
                          orderGroup: orderGroup._id,
                        });

                        setOrderGroup({
                          ...orderGroup,
                          status: res?.statusOrder,
                        });
                        openNotificationWithIcon("success", res.data.msg, "");
                      }}
                    >
                      Отправить
                    </Button>
                  ) : (
                    <p style={{ textAlign: "center", paddingTop: 10 }}>
                      Пожалуйста, отправьте сначала
                    </p>
                    // <img
                    //   alt={qrCode.type}
                    //   src={`${API_BASE_URL}/qrCode/${qrCode.img}`}
                    // />
                  )
                }
                //   actions={[
                //     <SettingOutlined key="setting" />,
                //     <EditOutlined key="edit" />,
                //     <EllipsisOutlined key="ellipsis" />,
                //   ]}
              ></Card>
              <Card
                style={{ width: 300 }}
                cover={
                  orderGroup.status === OrderStatus.RECIEVED_FROM_KZ_INNER ? (
                    <Result status="success" title="Получено" />
                  ) : orderGroup.status === OrderStatus.SENT_FROM_KZ_INNER ? (
                    <Button
                      onClick={async () => {
                        const res: any = await OrderService.recieveKzInner({
                          orderGroup: orderGroup._id,
                        });

                        setOrderGroup({
                          ...orderGroup,
                          status: res?.statusOrder,
                        });
                      }}
                    >
                      Принять
                    </Button>
                  ) : (
                    <p style={{ textAlign: "center", paddingTop: 10 }}>
                      Пожалуйста, отправьте сначала
                    </p>
                    // <img
                    //   alt={qrCode.type}
                    //   src={`${API_BASE_URL}/qrCode/${qrCode.img}`}
                    // />
                  )
                }
                //   actions={[
                //     <SettingOutlined key="setting" />,
                //     <EditOutlined key="edit" />,
                //     <EllipsisOutlined key="ellipsis" />,
                //   ]}
              ></Card>
            </div>
          </Card>
        </div>
      </Spin>

      {/* <Modal
        title="Филиал"
        maskClosable={false}
        open={wareHouseModal.open}
        onOk={handleWareHouseOk}
      >
        <Spin size="large" spinning={loadingWareHouse}>
          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder="Филиал таңдаңыз"
            optionFilterProp="children"
            value={wareHouseModal.warehouseSelected}
            onChange={(value) => {
              setWareHouseModal({
                ...wareHouseModal,
                warehouseSelected: value,
              });
            }}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {wareHouseModal.warehouses.map((warehouse: warehouseInterface) => (
              <Select.Option key={warehouse.key} value={warehouse.key}>
                {warehouse.text}
              </Select.Option>
            ))}
          </Select>
        </Spin>
      </Modal> */}
    </Drawer>
  );
};

export default FilialDetails;
