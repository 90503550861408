export const APP_NAME = "Emilus";

// export const SOCKET_URL = env.SOCKET_URL
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
// export const ENDPOINT = "http://localhost:5000/";
// export const API_BASE_URL = "http://localhost:5000/api";
// export const API_IMG_URL = "http://localhost:5000/api/img/";
export const API_BASE_URL = "https://api.galamlogistics.com/api";
export const ENDPOINT = "https://api.galamlogistics.com/";
export const API_IMG_URL = "https://api.galamlogistics.com/img/";
