import {
  Button,
  Card,
  Space,
  Tag,
  Table,
  Modal,
  Spin,
  Form,
  Input,
  Row,
} from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { ColumnsType } from "antd/lib/table";

import BarcodeReader from "react-barcode-reader";

import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import OrderService from "../../../service/OrderService";
import { OrderStatus } from "../home/statusOrder";
import {
  getOrderStatusEnumText,
  getOrderStatusEnumTextRus,
} from "../../../utils/getEnumText";
import { openNotificationWithIcon } from "../../../utils/notificationUtil";

interface DataType {
  orderData: orderType[];
  total: number;
}

interface orderType extends BaseOrderType {
  client_name: string;
  client_tel: string;
  status: OrderStatus;
  title: string;
  iin?: string;
}

interface BaseOrderType {
  _id: string;
  orders: number;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  recivedOrderCount: number;
}
interface openEditInterface {
  open: boolean;
  orderGroupId: string;
}

interface props {
  openEdit: openEditInterface;

  //   setOpenEdit: Dispatch<SetStateAction<openEditInterface>>;
}
const { confirm } = Modal;

const UpdateOrderBarCode = ({ openEdit }: props) => {
  const [orderLoading, setOrderLoading] = useState(true);
  const [scanBarcodeMode, setScanBarCodeMode] = useState(false);
  const [addLoading, setAddloaing] = useState(false);

  const [orders, setOrders] = useState<DataType>({
    orderData: [],
    total: 0,
  });

  useEffect(() => {
    const fetchOrder = async () => {
      setOrderLoading(true);

      const result = await OrderService.fetchOrder(openEdit.orderGroupId, 1);

      setOrders({ orderData: result.data.orders, total: result.data.total });

      setOrderLoading(false);
    };

    fetchOrder();
  }, [openEdit.orderGroupId]);

  const deleteOrder = async (id: string) => {
    const res = await OrderService.delete(id);

    const newOrderFilter = orders.orderData.filter((item) => {
      return item._id !== res.data;
    });

    setOrders({
      orderData: newOrderFilter,
      total: orders.total - 1,
    });
  };

  const deleteOrderConfirm = (id: string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Вы уверены, что хотите удалить штрих-код?",
      okText: "Да",
      cancelText: "Нет",
      onOk() {
        deleteOrder(id);
      },
    });
  };

  const columns: ColumnsType<orderType> = [
    {
      title: "штрих-код",
      dataIndex: "barCode",
      key: "name",
      render: (text) => <b>{text}</b>,
    },

    {
      title: "Статус",
      key: "status",
      dataIndex: "status",
      render: (text: string, record: orderType) => {
        const data = getOrderStatusEnumTextRus(
          record?.status || OrderStatus.PENDING
        );
        if (data) {
          return <Tag color={data.color}>{data.text}</Tag>;
        }
      },
    },
    {
      title: "IIN",
      key: "iin",
      dataIndex: "iin",
    },
    // {
    //   title: "Более",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <Button
    //         type="primary"
    //         danger
    //         onClick={() => deleteOrderConfirm(record._id)}
    //         //   onClick={() => openOrderTimeline(record)}
    //       >
    //         <DeleteOutlined />
    //       </Button>
    //     </Space>
    //   ),
    // },
  ];

  const orderPageChangeHandle = async (page: number, pageSize: number) => {
    setOrderLoading(true);
    const res = await OrderService.fetchOrder(openEdit.orderGroupId, page);
    setOrders({
      orderData: res.data.orders,
      total: res.data.total,
    });
    setOrderLoading(false);
  };
  const onFinish = (values: any) => {
    OrderService.scanAndUpdateOrder({
      barCode: values.barCode,
      orderGroup: openEdit.orderGroupId,
    })
      .then((res: any) => {
        setAddloaing(false);
        console.log(res);

        if (res.success == false) {
          openNotificationWithIcon("error", res.msg, "");
          return;
        }
        if (!!res?.order) {
          openNotificationWithIcon(
            "success",
            `已添加${res?.order.barCode}`,
            ""
          );

          setOrders({
            orderData: [res?.order, ...orders.orderData],
            total: orders.total + 1,
          });
        } else {
          openNotificationWithIcon("error", res.msg, "");
        }
      })
      .catch((err) => {
        console.error(err);
        setAddloaing(false);

        if (err.response.status === 422) {
          openNotificationWithIcon("error", `条码已存在`, "");
          return;
        }
        openNotificationWithIcon("error", err.response.msg, "");
      });
  };
  return (
    <Card
      className={scanBarcodeMode ? "scanMode" : ""}
      style={{ marginBottom: 10 }}
      //   bodyStyle={{ background: scanBarcodeMode ? "#e8e8e8" : "#fff" }}
    >
      <Row justify="space-between">
        <Button
          style={{ marginBottom: 20 }}
          type={scanBarcodeMode ? "default" : "primary"}
          onClick={() => setScanBarCodeMode((current) => !current)}
        >
          {scanBarcodeMode ? "Штрих-код режимінен бас тарту" : "штрих-код қосу"}
        </Button>
        {scanBarcodeMode ? (
          <Form
            name="basic"
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            onFinish={(values: any) => onFinish(values)}
            layout="inline"
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label=""
              name="barCode"
              // rules={[{ required: true, message: "Пожалуйста, введите длину!!" }]}
            >
              <Input placeholder="Штрих-код" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Қосу
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Row>
      <Table
        loading={orderLoading}
        rowKey="_id"
        columns={columns}
        dataSource={orders.orderData}
        pagination={{
          pageSize: 5,
          total: orders.total,
          onChange: orderPageChangeHandle,
        }}
      />
      {scanBarcodeMode === true ? (
        <BarcodeReader
          onError={(err: any) => {
            console.log(err);
          }}
          onScan={(data: string) => {
            setAddloaing(true);
            OrderService.scanAndUpdateOrder({
              barCode: data,
              orderGroup: openEdit.orderGroupId,
            })
              .then((res: any) => {
                setAddloaing(false);
                if (res.success == false) {
                  openNotificationWithIcon("error", res.msg, "");
                  return;
                }
                if (res?.order.barCode) {
                  openNotificationWithIcon(
                    "success",
                    `Сәтті【${res?.order.barCode}】`,
                    ""
                  );
                } else {
                  openNotificationWithIcon("error", `Қате `, "");
                }

                setOrders({
                  orderData: [res.order, ...orders.orderData],
                  total: orders.total + 1,
                });
              })
              .catch((err) => {
                console.log(err);
                setAddloaing(false);

                if (err.response.status === 422) {
                  openNotificationWithIcon(
                    "error",
                    `Штрих-код уже существует`,
                    ""
                  );
                }
                // openNotificationWithIcon(
                //   "error",
                //   `${res.barCode} 条码已存在`,
                //   ""
                // );
              });

            // setScanCodes((current) => [...current, data]);
          }}
        />
      ) : null}
      {/* <Table
      loading={orderLoading}
      columns={columns}
      dataSource={orders.orderData}
      // expandable={{
      //   expandedRowRender,
      //   expandedRowKeys: orderExpandedRowKeys,
      //   onExpand: onExpandedRowsChange,
      // }}
      rowKey="_id"
      pagination={{
        pageSize: 12,
        total: orders.total,
        onChange: orderGroupPageChangeHandle,
      }}
    /> */}

      <Modal
        centered
        width={"min-content"}
        closable={false}
        title=""
        open={addLoading}
        footer={null}
        onCancel={() => setAddloaing(false)}
      >
        <Spin />
      </Modal>
    </Card>
  );
};

export default UpdateOrderBarCode;
