import { Layout, Menu } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { selectToken } from "../../redux/slices/userSlice";

import Home from "./home";
import ScanReader from "./scanReader";
import City from "./citis";
import Filial from "./filial";
import SearchBarcode from "./searchBarcode";

function AppView() {
  let activeClassName = "underline";
  let history = useNavigate();
  const accessToken = useSelector(selectToken);
  const location = useLocation();
  useEffect(() => {
    if (!accessToken) {
      history("../login", { replace: true });
    }
  }, []);
  return (
    <Layout>
      {location.pathname !== "/admin/filial" ? (
        <Header style={{ backgroundColor: "#fff" }}>
          <Menu mode="horizontal" defaultSelectedKeys={["mail"]}>
            <Menu.Item key="orders">
              {" "}
              <NavLink
                to="/admin/orders"
                className={({ isActive }) =>
                  isActive ? activeClassName : undefined
                }
              >
                订单
              </NavLink>
            </Menu.Item>
            <Menu.Item key="scan">
              {" "}
              <NavLink
                to="/admin/scan"
                className={({ isActive }) =>
                  isActive ? activeClassName : undefined
                }
              >
                取货（扫描）
              </NavLink>
            </Menu.Item>

            <Menu.Item key="cities">
              {" "}
              <NavLink
                to="/admin/cities"
                className={({ isActive }) =>
                  isActive ? activeClassName : undefined
                }
              >
                分站
              </NavLink>
            </Menu.Item>

            <Menu.Item key="search">
              {" "}
              <NavLink
                to="/admin/search"
                className={({ isActive }) =>
                  isActive ? activeClassName : undefined
                }
              >
                搜索
              </NavLink>
            </Menu.Item>

            <Menu.Item key="filial">
              {" "}
              <NavLink
                to="/admin/filial"
                className={({ isActive }) =>
                  isActive ? activeClassName : undefined
                }
              >
                Филиал
              </NavLink>
            </Menu.Item>

            {/* <Menu.Item key="filial">
              {" "}
              <NavLink
                to="/admin/filial"
                className={({ isActive }) =>
                  isActive ? activeClassName : undefined
                }
              >
                Филиалға жіберу
              </NavLink>
            </Menu.Item> */}
          </Menu>
        </Header>
      ) : null}

      <Content>
        <Routes>
          <Route path="cities" element={<City />} />
          <Route path="orders" element={<Home />} />
          <Route path="scan" element={<ScanReader />} />
          <Route path="filial" element={<Filial />} />
          <Route path="search" element={<SearchBarcode />} />
        </Routes>
      </Content>
    </Layout>
  );
}

export default AppView;
